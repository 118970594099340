import { FC, useEffect, useRef } from 'react'

// Attempting to use normal CSS magic (clip + background gradient) to get
// gradient text working failed when printing to PDF. Instead we use SVGs!
export const SvgGradientText: FC<{
  className?: string
  textClass?: string
  text: string
  color1: string
  color2: string
}> = ({ className, textClass, text, color1, color2 }) => {
  const gradientId = 'agency-gradient'
  const svgRef = useRef<any>(null)
  const textRef = useRef<any>(null)

  // Auto size our svg to fit the text.
  useEffect(() => {
    const svg = svgRef.current
    const text = textRef.current.getBBox()
    svg.setAttribute('width', text.width)
    svg.setAttribute('height', text.height)
    svg.setAttribute(
      'viewBox',
      `${text.x} ${text.y} ${text.width + 10} ${text.height}`
    )
  }, [])

  return (
    <svg className={className} ref={svgRef} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id={gradientId}>
          <stop offset="0%" stopColor={color1} />
          <stop offset="100%" stopColor={color2} />
        </linearGradient>
      </defs>
      <g ref={textRef}>
        {/* This copy of the text is a solid color to act as a fallback for viewers that aren't able to render the gradient. For some reason, Chrome in particular, when rendering the PDF, does not show the gradient text at all. This solid color text will be visible instead if the gradient text is not rendered. */}
        <text
          className={textClass}
          style={{ fill: `${color1}` }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <text
          className={textClass}
          style={{ fill: `url(#${gradientId})` }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </g>
    </svg>
  );
}
